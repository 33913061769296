import Vue from "vue";
Vue.directive("inputFocus", {
  bind(el, modifiers) {
    const { value } = modifiers;
    el.addEventListener("focus", () => {
      let labels = document.querySelectorAll(`.${value.className}`);
      const label = labels[value.index];
      label.classList.remove("label-default");
      label.classList.add("label-placeholder");
    });
    el.addEventListener("blur", () => {
      let labels = document.querySelectorAll(`.${value.className}`);
      const label = labels[value.index];
      if (value.form[value.formKey] !== "") {
        label.classList.remove("label-default");
        label.classList.add("label-placeholder");
      } else {
        label.classList.add("label-default");
        label.classList.remove("label-placeholder");
      }
    });
  }
});

Vue.directive("label-focus", {
  bind(el, modifiers) {
    const { value } = modifiers;
    el.addEventListener("click", evt => {
      const inputs = document.querySelectorAll(`.${value.className}`);
      inputs[value.index].focus();
      evt.target.classList.remove("label-default");
      evt.target.classList.add("label-placeholder");
    });
  }
});

Vue.directive("on-scroll", {
  inserted(el, binding) {
    const f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  }
});
Vue.directive("on-window-change", {
  bind(el) {
    if (window.innerWidth <= 784) {
      el.classList.add("header--active");
    } else {
      el.classList.remove("header--active");
    }

    window.addEventListener("resize", function() {
      if (this.innerWidth <= 784) {
        el.classList.add("header--active");
      } else {
        el.classList.remove("header--active");
      }
    });
  }
});
