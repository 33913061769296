import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/index.vue")
  },
  {
    path: "/our-companies",
    name: "ourCompanies",
    component: () => import("@/views/our-companies")
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () => import("@/views/about-us")
  },
  {
    path: "/sharing-details",
    name: "sharing-details",
    component: () => import("@/views/sharing-details")
  },
  {
    path: "/investments-invitation",
    name: "investments-invitation",
    component: () => import("@/views/investments-invitation")
  },
  {
    path: "/money-details",
    name: "money-details",
    component: () => import("@/views/money-details")
  },
  {
    path: "/404",
    name: "404",
    // redirect: { name: "404" },
    component: () => import("@/views/404")
  },
  {
    path: "*",
    name: "notFound",
    redirect: { name: "404" }
    // component: () => import('../views/404')
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  next();
});

export default router;
