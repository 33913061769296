const overlay = {
  state: {
    overlayToggle: false,
    videoToggle: false
  },
  mutations: {
    setOverlay(state, payload) {
      state.overlayToggle = !state.overlayToggle;
      state.videoToggle = payload;
    }
  },
  actions: {
    SET_OVERLAY({ commit }, payload = false) {
      commit("setOverlay", payload);
    }
  },
  getters: {
    getOverlayState(state) {
      return state.overlayToggle;
    },
    getVideoState(state) {
      return state.videoToggle;
    }
  }
};

export default overlay;
