import axios from "axios";
import { baseURL } from "@/config/requests";
import store from "@/store";
import Cookies from "js-cookie";
// import { getToken } from "@/utils/auth";

// create an axios instance
const service = axios.create({
  baseURL: baseURL,
  // baseURL: process.env.VUE_APP_BASE_URL, // api base_url

  timeout: 20000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    if (config.headers["X-locale"] === undefined) {
      config.headers["X-locale"] = Cookies.get("language");
    }

    // Update Request Put locale property in request data
    if (
      config.method === "put" ||
      (config.data && config.data._method && config.data._method === "put")
    ) {
      config.data.locale = Cookies.get("language");
    }
    return config;
  },
  error => {
    // Do something with request error
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    store.dispatch("ClearServerErrors");

    return response;
  },

  error => {
    // global error response message

    const { errors } = error.response.data;
    store.dispatch("SetServerErrors", errors);
    return Promise.reject(error);
  }
);

export default service;
