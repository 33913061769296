import Vue from "vue";
import Vuelidate from "vuelidate";
import vuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";
import FormGroup from "@/components/FormGroup";
import { messages } from "@/lang";
import PortalVue from "portal-vue";
Vue.use(PortalVue);
Vue.use(Vuelidate);
Vue.use(vuelidateErrorExtractor, {
  messages,
  i18n: "validation"
});
import VueContentPlaceholders from "vue-content-placeholders";

Vue.use(VueContentPlaceholders);
Vue.component("FormGroup", FormGroup);
Vue.component("formWrapper", templates.FormWrapper);
