import Vue from "vue";
import Vuex from "vuex";
import errors from "./modules/errors";
import localization from "./modules/localization";
import settings from "./modules/globalSettings";
import overlay from "./modules/overlay";

import getters from "./getters";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: { errors, localization, settings, overlay },
  getters,

  state: {
    globalOverlay: {
      type: "",
      isOpen: false,
      projectId: null,
      typeIndex: 0,
      demos: null
    }
  },
  mutations: {
    SET_GLOBAL_OVERLAY(state, payload) {
      state.globalOverlay = payload;
    }
  },
  actions: {
    SetGlobalOverlay({ commit }, payload) {
      commit("SET_GLOBAL_OVERLAY", payload);
    }
  }
});
