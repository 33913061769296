import cookies from "js-cookie";

if (cookies.get("language") === "ar") {
  import("@/assets/style/css/bootstrap-rtl.min.css");
  import("@/assets/fonts/ar/stylesheet.css");
  import("@/assets/style/scss/ar/main.scss");
} else {
  import("@/assets/fonts/en/stylesheet.css");
  import("@/assets/style/scss/en/main.scss");
}
