import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import BootstrapVue from "bootstrap-vue";
import Cookies from "js-cookie";
import i18n from "@/lang";
import VueAwesomeSwiper from "vue-awesome-swiper";
import ReadMore from "vue-read-more";

import "./bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "swiper/dist/css/swiper.css";
import "@/assets/fonts/icons/css/fontello.css";

Vue.use(VueAwesomeSwiper /* { default global options } */);
Vue.use(BootstrapVue);
Vue.use(ReadMore);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  created() {
    if (!Cookies.get("language")) {
      Cookies.set("language", "ar");
      window.location.reload();
    }
    this.$store.dispatch("setSettings");
  },
  render: h => h(App)
}).$mount("#app");
