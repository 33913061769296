<template>
  <main>
    <section v-if="!loading">
      <Header />
      <OverLay
        :showOverLay="showOverLay"
        @hide_overLay="overlayToggle"
        @close_overlay="overlayToggle"
      />
      <transition name="fade" mode="in-out">
        <router-view></router-view>
      </transition>

      <Footer :contacts="contacts" />
    </section>
    <loader v-else />
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "app",
  components: {
    Header: () => import("@/components/Layout/Header"),
    loader: () => import("@/components/loader"),
    Footer: () => import("@/components/Layout/Footer"),
    OverLay: () => import("@/components/OverLay")
  },
  data() {
    return {
      loading: true
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  methods: {
    overlayToggle() {
      this.$store.dispatch("SET_OVERLAY");
    }
  },
  computed: {
    ...mapGetters(["settings"]),
    contacts() {
      return this.settings("contacts");
    },
    showOverLay() {
      return this.$store.getters.getOverlayState;
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
